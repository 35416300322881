.button {
    border-radius: 10px;
    outline: none;
    border: none;
    cursor: pointer;
    transition: background-color var(--animation-duration), color var(--animation-duration);
    display: flex;
    gap: 10px;
    align-items: center;
    white-space: nowrap;
    justify-content: center;
}

.sizeDefault {
    padding: 10px 15px;
    font-size: 14px;
}

.sizeSmall {
    padding: 6px 15px;
    font-size: 12px;
    line-height: 14px;
}

.button > svg {
    flex-shrink: 0;
}

.button:focus-visible {
    outline: 2px solid var(--dark-focus);
}

.colorWarning {
    background-color: var(--dark-status-danger-d300);
    color: var(--dark-white);
}

.colorWarning:hover {
    background-color: var(--dark-status-danger-d100);
    color: var(--dark-status-danger-d400);
}

.colorPrimary {
    background-color: var(--dark-primarydark-secondary);
    color: var(--dark-primary-main);
}

.colorPrimary:hover {
    background-color: var(--dark-primarydark-p300);
}

.colorSecondary {
    background-color: var(--dark-primarydark-secondary-2);
    color: var(--dark-primary-main);
}

.colorSecondary:hover {
    background-color: var(--dark-primarydark-p300);
}

.colorGray {
    background-color: var(--dark-gray-p500);
    color: var(--dark-white);
}

.colorGray:hover {
    background-color: var(--dark-gray-p500-50);
}

.buttonTransparent {
    padding: 5px;
}

.colorTransparentPrimary {
    composes: buttonTransparent;
    background-color: transparent;
    color: var(--dark-primary-main);
}

.colorTransparentWarning {
    composes: buttonTransparent;
    background-color: transparent;
    color: var(--dark-status-danger-d300);
}

.colorTransparentWarning:hover {
    color: var(--dark-status-danger-d100);
}

.colorTransparentPrimary[disabled], .colorTransparentWarning[disabled] {
    color: var(--dark-gray-p200);
}

.colorPrimary:disabled, .colorWarning:disabled, .colorSecondary:disabled {
    background-color: var(--dark-gray-p500);
    color: var(--dark-gray-p200);
    cursor: default;
}
