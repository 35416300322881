.variantDefault {
    --text-color: var(--dark-white);
    --text-disabled-color: var(--dark-white);
}

.variantHighlight {
    --text-color: var(--dark-primary-main);
    --text-disabled-color: var(--dark-white);
}

.text {
    cursor: pointer;
    padding: 6px;
    color: var(--text-color);
    border-radius: 10px;
    word-break: break-all;
}

.textDisabled {
    composes: text;
    color: var(--text-disabled-color);
}

.text:hover {
    background-color: var(--dark-primarydark-secondary);
    color: var(--dark-white);
}
