@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700&display=swap');

body {
    background: var(--background-2);

    /* Fonts */
    --font: "Roboto", sans-serif;

    /* Background Colors */
    --background-1: #21253D;
    --background-2: #262A42;
    --background-3: #1C20398A;
    --background-4: #21253D;
    --background-5: #161929;
    --background-6: #71768847;
    --background-7: #71768821;
    --background-8: #FF545447;
    --background-9: #FF545421;
    --background-10: #262A4299;
    --background-11: #717688;
    --background-12: rgba(73, 76, 96, 0.28);
    --background-13: rgba(73, 76, 96, 0.13);
    --background-14: white;
    --background-15: #3F4254;
    --background-16: #3F435E;
    --background-ripple: #42DC9C80;
    --background-accent-1: #42dc9c;
    --background-accent-2: rgba(66, 220, 156, 0.13);
    --background-accent-3: rgba(66, 215, 160, 0.28);
    --background-accent-4: rgba(66, 220, 156, 0.6);
    --background-accent-5: rgba(66, 220, 156, 0.2);
    --background-danger-1: #FF5454;
    --background-skeleton: #494C60;
    --background-skeleton-anim: #717688;

    /* Text Color */
    --text-primary: #FFFFDD;
    --text-secondary: #717688;
    --text-accent: #42DC9C;
    --text-danger: #FF5454;


    /*
        NEW COLOR PALETTE
    */

    --animation-duration: 0.1s;

    --dark-gray-main: #494c60;
    --dark-gray-mian: #494c60;
    --dark-gray-p50: #CED0DA;
    --dark-gray-p75: #B1B4C4;
    --dark-gray-p100: #9498ae;
    --dark-gray-p200: #777B98;
    --dark-gray-p400: #333543;
    --dark-gray-p500: #1d1e26;
    --dark-gray-p500-50: rgba(29, 30, 38, .5);
    --dark-status-danger-d100: #f29696;
    --dark-status-danger-d200: #EC6969;
    --dark-status-danger-d300: #e84a4a;
    --dark-status-danger-d400: #a23434;
    --dark-status-danger-d500: #8E2D2D;
    --dark-status-warning-w200: #FABF66;
    --dark-status-warning-w300: #F9B247;
    --dark-status-warning-w500: #986D2B;
    --dark-primary-main: #42dc9c;
    --dark-primary-p50: #EEFCF6;
    --dark-secondary-p100: #5761A0;
    --dark-secondary-p200: #454D7F;
    --dark-secondary-p400: #0F111C;
    --dark-secondary-main: #21253D;
    --dark-secondary-fmain: #262A42;
    --dark-primarydark-secondary: #2c4e54;
    --dark-primarydark-secondary-2: #2C4E544D;
    --dark-primarydark-p300: #1a2f33;
    --dark-primarydark-p400: #091011;
    --dark-white: #FFFFDD;
    --dark-no-name-30: rgba(60, 60, 60, 0.3);

    --dark-focus: white;
}

/* THEME */

* {
    font-family: var(--font);
}

html {
    overflow: hidden;
}

html, body, #root {
    height: 100%;
}

/**
    Scrollbar
 */

::-webkit-scrollbar {
    background: var(--background-2);
    width: 15px;
}

::-webkit-scrollbar-thumb {
    background: var(--dark-gray-p200);
    position: relative;
    display: flex;
    border: 5px solid var(--dark-secondary-fmain);
    border-radius: 10px;
}

::-webkit-scrollbar-corner {
    background: var(--background-2);
}

/*
    Popup
*/

.popup-content {
    z-index: 1001 !important;
}

.popup-below-layer-content {
    z-index: 999 !important;
}

.tooltip-content {
    padding: 15px;
    font-family: var(--font);
    font-size: 16px;
    color: var(--text-primary);
    background-color: var(--background-3);
    border-radius: 15px;
    animation: popupShow .5s;
}

@keyframes popupShow {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.leaflet-popup {
    transition: none !important;
}

.leaflet-popup-content-wrapper.leaflet-popup-content-wrapper {
    border-radius: 0;
    padding: 0;
    background-color: transparent;
    box-shadow: none;
}

.leaflet-popup-tip.leaflet-popup-tip {
    background-color: var(--dark-secondary-p400);
}

.leaflet-popup-content.leaflet-popup-content {
    margin: 0;
}

.leaflet-popup-content p {
    margin: initial!important;
}

.leaflet-popup-close-button {
    display: none;
}

.leaflet-div-icon.leaflet-div-icon {
    border: none;
    background-color: transparent;
}

.leaflet-container:focus-visible {
    outline: none;
}