.content {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.highlight {
    color: var(--dark-primary-main);
    word-break: break-all;
}
