.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--dark-gray-p500-50);
    z-index: 1002;
}

.window {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    background-color: var(--background-5);
    padding: 40px;
    border-radius: 20px;
    z-index: 1003;
}
