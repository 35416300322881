.variantDefault {
    --checkbox-background: var(--dark-gray-main);
    --checkbox-active-background: var(--dark-primarydark-secondary);
    --checkbox-active-background-darker: var(--dark-primarydark-p300);
}

.variantListed {
    --checkbox-background: var(--dark-gray-p400);
    --checkbox-active-background: var(--dark-primarydark-secondary);
    --checkbox-active-background-darker: var(--dark-primarydark-p300);
}

.labelContainer {
    display: flex;
    gap: 8px;
    align-items: center;
    cursor: pointer;
}

.realCheckbox {
    position: absolute;
    width: 0;
    height: 0;
    opacity: 0;
}

.checkbox {
    flex-shrink: 0;
    width: 16px;
    height: 16px;
    border-radius: 4px;
    background-color: var(--checkbox-background);
}

.checkboxChecked {
    composes: checkbox;
    display: grid;
    place-items: center;
    border: none;
}

.checkboxChecked svg {
    fill: var(--dark-white);
}

.realCheckbox:focus-visible ~ .checkbox {
    outline: 2px solid var(--dark-focus);
}
