.input {
    font-family: var(--font);
    line-height: 18px;
    font-size: 16px;
    color: var(--text-primary);
    background-color: transparent;
    border: none;
    width: 100%;
    box-sizing: border-box;
}

.input::placeholder {
    color: var(--text-secondary);
}

.input:focus {
    outline: none;
}

/**
    Variants
 */

.primary {
    composes: input;
}

.form {
    composes: input;
    padding: 10px 18px;
    font-size: 14px;
    border: 1px solid var(--background-skeleton);
    border-radius: 10px;
    background-color: var(--background-2);
}

.form::placeholder {
    color: var(--text-secondary);
}

.form:disabled {
    color: var(--text-secondary);
    background-color: var(--background-6);
}
