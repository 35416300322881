/*region Container*/
.containerWrapper {
    display: flex;
    gap: 30px;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
}

.container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-wrap: wrap;
}

/*endregion*/

/*region ItemBase*/
.itemBase {
    padding: 5px 5px;
    min-width: 32px;
    min-height: 32px;
    box-sizing: border-box;
    display: grid;
    place-items: center;
    border-radius: 10px;
    transition: background-color .1s;
    outline: 3px double transparent;
}

.itemBase:focus-visible {
    outline-color: var(--background-14);
}

/*endregion*/

/*region Page Navigation Buttons*/
.pageNav {
    fill: var(--dark-primary-main);
}

.pageNavDisabled {
    fill: var(--dark-gray-mian);
}

/*endregion*/

/*region Item*/
.item {
    composes: itemBase;
    cursor: pointer;
    color: var(--dark-white);
}

.item:hover, .itemIcon:hover {
    background-color: var(--dark-primarydark-secondary);
}

.itemActive {
    composes: itemBase;
    color: var(--dark-secondary-fmain);
    background-color: var(--dark-primary-main);
}

.itemIcon {
    composes: itemBase;
    cursor: pointer;
    color: var(--dark-primary-main);
}

.itemDisabled {
    composes: itemBase;
    color: var(--dark-gray-mian);
}

/*endregion*/
