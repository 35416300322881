.container {
    position: relative;
}

.menuList {
    padding-right: 10px;
    overflow: auto;
}

.menuList::-webkit-scrollbar-thumb {
    background-color: var(--dark-gray-p50);
    border-width: 0;
}

.menuList::-webkit-scrollbar {
    background-color: var(--dark-gray-mian);
    border-radius: 11px;
    width: 8px;
}

.multiValueContainer {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.selectedBadges {
    display: flex;
    gap: 6px;
    flex-flow: wrap;
}

.selectedBadgesScroll {
    max-height: 90px;
    overflow: auto;
}

.selectedBadgesScroll::-webkit-scrollbar {
    background: transparent;
}

.selectedBadgesScroll::-webkit-scrollbar-thumb {
    border-color: var(--dark-gray-p400);
}

/*region Option*/
.option {
    display: flex;
    gap: 10px;
    align-items: center;
    cursor: pointer;
    padding: 10px;
    border-radius: 10px;
    color: var(--dark-gray-p75);
    background-color: var(--dark-gray-mian);
    margin-bottom: 5px;
    border: 1px solid var(--dark-gray-mian);
}

.optionUnlabeled {
    justify-content: center;
}

.option:last-child {
    margin-bottom: 0;
}

.optionActive {
    color: var(--dark-primary-main) !important;
    background-color: var(--dark-primarydark-secondary) !important;
    border-color: var(--dark-primarydark-secondary) !important;
}

.optionDisabled {
    background-color: var(--dark-gray-p400);
    border-color: var(--dark-gray-p200);
}

/*endregion*/

/*region Bullet*/
.bullet {
    display: flex;
    align-items: center;
    gap: 5px;
    border-radius: 8px;
    padding: 5px 6px;
    cursor: pointer;
}

.bulletClose {
    flex-shrink: 0;
}

.labelContainer {
    display: flex;
    gap: 5px;
    flex-direction: column;
}

.labelText {
    overflow-wrap: break-word;
    word-break: break-word;
}

/*endregion*/

/*region Loading Indicator*/
.loader {
    display: flex;
    justify-content: center;
    background-color: var(--dark-gray-p400);
    padding: 10px 5px;
    border-radius: 10px;
}

.loader > div {
    width: 5px;
    height: 5px;
    margin: 0 2px;
    border-radius: 50%;
    background-color: var(--dark-gray-p50);
    opacity: 1;
    animation: bouncing-loader 0.6s infinite alternate;
}

@keyframes bouncing-loader {
    to {
        opacity: 0.1;
        transform: translateY(-2px);
    }
}

.loader > div:nth-child(2) {
    animation-delay: 0.2s;
}

.loader > div:nth-child(3) {
    animation-delay: 0.4s;
}

/*endregion*/

.inputContainer {
    display: grid;
    position: relative;
    top: 0;
    left: 0;
    grid-area: 1/1/2/3;
}

.inputContainerGrow {
    position: sticky;
    top: 0;
    left: 0;
    white-space: pre-wrap;
    visibility: hidden;
    height: 100%;
}

.inputContainer > textarea {
    overflow: hidden;
}

.inputContainer > textarea,
.inputContainerGrow {
    font-size: 14px;
    line-height: 16px;
    box-sizing: border-box;
    width: calc(100% - 10px);
    word-wrap: break-word;
    font-family: var(--font), "sans-serif";
    margin: 0;
    padding: 0;
    grid-area: 1 / 1 / 2 / 2;
}