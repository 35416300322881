.label {
    display: block;
}

.bottomPadding {
    margin-bottom: 20px;
}

.labelTitle {
    display: block;
    font-family: var(--font);
    font-size: 16px;
    color: var(--dark-gray-p200);
    margin-bottom: 5px;
}

.error {
    font-family: var(--font);
    font-size: 14px;
    color: var(--text-danger);
    display: block;
    padding-top: 5px;
}
