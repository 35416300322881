.container {
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
}

.indicatorContainer {
    background-color: var(--dark-gray-mian);
    border: 1px solid var(--dark-gray-p400);
    border-radius: 50%;
    width: 16px;
    height: 16px;
    box-sizing: border-box;
    flex-shrink: 0;
}

.indicatorContainerActive {
    border-radius: 50%;
    width: 16px;
    height: 16px;
    flex-shrink: 0;
    background-color: var(--dark-primarydark-secondary);
    display: grid;
    place-items: center;
}

.indicatorContainerActive::before {
    content: "";
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: var(--dark-white);
}
