.content {
    margin: 0 var(--route-padding);
    padding-bottom: var(--route-padding);
    display: grid;
    grid-template-columns: 1fr 0.7fr 370px;
    grid-template-rows: auto 1fr;
    height: 100%;
    box-sizing: border-box;
    grid-template-areas:
    "I I I"
    "A T B";
    gap: 12px;
}

.contentBlock {
    padding: 12px;
    background-color: var(--dark-secondary-main);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    min-height: 0;
}

.info {
    grid-area: I;
}

.addresses {
    grid-area: A;
}

.tasks {
    grid-area: T;
}

.tasksPlaceholder {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    gap: 15px;
}

.balanceHistory {
    grid-area: B;
}

.columnType {
    display: flex;
    gap: 10px;
    align-items: center;
    cursor: pointer;
}

.columnSecondName {
    display: flex;
    gap: 10px;
    align-items: center;
}

.columnSecondNameSkeleton {
    flex-grow: 1;
}

.columnSecondNameField {
    flex-grow: 1;
}

.tableWithPagination {
    display: flex;
    flex-direction: column;
    gap: 10px;
    overflow: auto;
    flex: 1;
}

.tableWithPagination::-webkit-scrollbar-track {
    background-color: var(--dark-secondary-main);
}