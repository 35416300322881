.messageBox {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.action {
    --action-padding: 5px;

    fill: var(--dark-primary-main);
    padding: var(--action-padding);
    cursor: pointer;
    border-radius: 5px;
}

.action:hover {
    background-color: var(--dark-primarydark-secondary);
}

.actionDisabled {
    composes: action;
    fill: var(--dark-gray-p200);
    cursor: default;
}

.actionDisabled:hover {
    background-color: transparent;
}

.actionSend {
    margin-right: calc(var(--input-horizontal-padding) - var(--action-padding));
}

.actionClip {
    margin-left: calc(var(--input-horizontal-padding) - var(--action-padding));
}

.clipContainer {
    display: flex;
    align-items: center;
    gap: 5px;
    flex-wrap: wrap;
}

/*region Attachment*/
.attachmentList {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 5px;
}

.attachment {
    display: grid;
    grid-template:
    "I T R"
    "I N R";
    grid-template-columns: auto 1fr auto;
    column-gap: 8px;
    color: var(--dark-primary-main);
    background-color: var(--dark-primarydark-secondary);
    border-radius: 10px;
    padding: 5px;
    align-items: center;
    cursor: pointer;
}

.attachment:hover {
    outline: 2px solid var(--dark-focus);
}

.attachmentIcon {
    grid-area: I;
}

.attachmentType {
    grid-area: T;
}

.attachmentName {
    grid-area: N;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

/*endregion*/

.attachmentRemove {
    grid-area: R;
}

.realFileInput {
    display: none;
    width: 0;
    height: 0;
}

/*region Attachments Container*/
.attachments {
    display: flex;
    flex-direction: column;
    width: 100%;
    row-gap: 5px;
    column-gap: 15px;
}

.attachmentsHeader {
    display: flex;
    gap: 15px;
    align-items: center;
}

.attachmentsList {
    grid-column: span 2;
}

/*endregion*/
