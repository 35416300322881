.formContainer {
    display: flex;
    gap: 40px;
    width: 600px;
}

.form {
    display: flex;
    flex-direction: column;
    width: 300px;
}

.avatarPanel {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    flex: 1;
}

.avatarChange {
    margin-bottom: 20px;
}

.error {
    color: var(--text-danger);
}

.password {
    display: flex;
    align-items: center;
}

.passwordShowIcon {
    margin: 0 10px;
    cursor: pointer;
    transition: fill .3s;
}

.passwordShowIcon:hover {
    fill: var(--background-accent-1)
}

.realAvatarInput {
    display: none;
    width: 0;
    height: 0;
}
