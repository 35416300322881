.containerWithBadges {
    display: flex;
}

.container {
    display: flex;
    gap: 10px;
    height: 36px;
    padding: 0 10px;
    align-items: center;
    background-color: var(--dark-gray-p500);
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
    cursor: text;
    flex: 1;
    box-sizing: border-box;
    max-width: 250px;
}

.containerFill {
    max-width: initial;
}

.containerLight {
    background-color: var(--dark-gray-p400);
}

.containerWithHeading {
    border-start-start-radius: 0;
    border-end-start-radius: 0;
    border-left: 1px solid var(--dark-gray-p400);
}

.searchIcon {
    flex-shrink: 0;
}

.realInput {
    border: none;
    outline: none;
    background-color: transparent;
    font-weight: 300;
    font-size: 14px;
    color: var(--dark-white);
    flex: 1;
    width: 100%;
}

.realInput::placeholder {
    color: var(--dark-gray-p100);
}

.clearBtn {
    padding: 5px;
    border-radius: 50%;
    cursor: pointer;
}

.clearBtn:hover {
    background-color: var(--dark-gray-p400);
}

.searchBtnContainer {
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
}

.searchBtn {
    outline: none;
    border: none;
    background-color: var(--dark-primarydark-secondary);
    cursor: pointer;
    font-size: 12px;
    padding: 10px 19px;
    height: 100%;
    color: var(--dark-primary-main);
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
}

.searchBtnContainer:hover {
    outline: 2px solid var(--dark-focus);
}