.asideTitle {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    flex: 1;
    min-width: 0;
}

/**
    Profile
*/

.profileUser {
    display: flex;
    flex-direction: column;
    gap: 5px;
    max-width: 300px;
}

.profileName {
    display: flex;
    gap: 10px;
    align-items: center;
}

.profileEmail {
    overflow: hidden;
    text-overflow: ellipsis;
}

.profileWrapper {
    border-radius: 25px;
    transition: box-shadow .3s;
    height: 60px;
    position: relative;
}

.profile {
    display: flex;
    align-items: center;
    height: 60px;
    border-radius: 25px;
    box-sizing: border-box;
    cursor: pointer;
    background-color: var(--background-2);
    gap: 10px;
    transition: border-radius .3s, background-color .3s;
    z-index: 2;
    padding: 10px;
}

.profileOpened .profile {
    border-radius: 25px 25px 0 0;
    background-color: var(--background-3);
}

.profileDropdown {
    stroke: var(--text-primary);
    transition: transform .3s;
}

.profileOpened .profileDropdown {
    transform: rotateZ(-180deg);
}

/*
    Profile Menu
*/
.profileMenu {
    display: flex;
    flex-direction: column;
    gap: 4px;
    background-color: var(--background-3);
    border-radius: 0 0 20px 20px;
    width: 100%;
    padding: 13px 0;
    box-sizing: border-box;
    opacity: 0;
    transition: opacity .35s, margin-top .3s, max-height .3s;
    margin-top: 0;
    max-height: 0;
    position: absolute;
    z-index: 999;
    pointer-events: none;
}

.profileMenuOpened {
    max-height: 100px;
    opacity: 1;
    margin-top: 60px;
    pointer-events: initial;
}

/*
    Profile Menu Item
*/
.profileMenuItem {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 7px 25px;
}

.profileMenuItemIcon {
    fill: var(--text-primary) !important;
    stroke: var(--text-primary);
    transition: fill .3s, stroke .3s;
}

.profileMenuItem:hover .profileMenuItemIcon {
    fill: var(--background-accent-1) !important;
    stroke: var(--background-accent-1);
}

.profileMenuItemTitle {
    transition: color .3s;
}

.profileMenuItem:hover .profileMenuItemTitle {
    color: var(--text-accent)
}
