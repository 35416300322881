.navigation {
    display: flex;
    background-color: var(--dark-secondary-main);
    flex-direction: column;
    width: 100px;
    height: 100%;
    align-items: center;
    box-sizing: border-box;
}

.logo {
    width: 100%;
    height: 100px;
    display: grid;
    place-items: center;
}

.logoChristmas {
    composes: logo;
    padding-left: 10px;
}

/*region Navigation Item*/
.navigationItemRipple {
    border-radius: 12px;
}

.navigationItem {
    display: grid;
    place-items: center;
    justify-self: center;
    cursor: pointer;
    height: 58px;
    width: 58px;
    box-sizing: border-box;
    transition: background-color .3s;
}

.navigationItemSelected, .navigationItem:hover {
    background-color: var(--dark-secondary-fmain);
}

.navigationItem svg {
    fill: var(--dark-gray-mian);
    stroke: var(--dark-gray-mian);
    transition: fill .3s, stroke .3s;
}

.navigationItemSelected svg, .navigationItem:hover svg {
    fill: var(--dark-primary-main);
    stroke: var(--dark-primary-main);
}

/*endregion*/
