.container {
    --input-horizontal-padding: 10px;

    display: flex;
    border-radius: 10px;
    background-color: var(--dark-gray-p400);
    border: 1px solid var(--dark-gray-p400);
    align-items: center;
    min-height: 36px;
    box-sizing: border-box;
    cursor: text;
}

.containerFocus {
    composes: container;
    border-color: var(--dark-white);
}

.containerDisabled {
    background-color: var(--dark-gray-p500);
    border-color: var(--dark-gray-p500);
}

.realInput {
    outline: none;
    font-size: 14px;
    line-height: 16px;
    color: var(--dark-gray-p100);
    background-color: transparent;
    flex-grow: 1;
    border: none;
    width: 0;
}

.realInput:-webkit-autofill,
.realInput:-webkit-autofill:hover,
.realInput:-webkit-autofill:focus,
.realInput:-webkit-autofill:active {
    -webkit-background-clip: text;
    -webkit-text-fill-color: var(--dark-white);
    caret-color: var(--dark-white);
}

.realInput:disabled {
    background-color: var(--dark-gray-p500);
    color: var(--dark-gray-p200);
}

.realInput::placeholder {
    color: var(--dark-gray-p200);
}

.realInput:focus {
    color: var(--dark-white);
}

.realInput:disabled::placeholder {
    color: var(--dark-gray-p200);
}
