.rippleContainer {
    border-radius: 9px;
}

.button {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    padding: 8px 15px;
    font-family: var(--font);
    font-size: 12px;
    border-radius: 9px;
    box-sizing: border-box;
    letter-spacing: 0.05em;
    border: none;
    background-color: transparent;
    width: 100%;
    height: 100%;
}

.button[disabled] {
    background-color: var(--background-6);
    color: var(--text-secondary);
    border: 2px solid var(--background-7);
    cursor: default;
}

.buttonDisabled {
    composes: button;
    background-color: var(--background-6);
    color: var(--text-secondary);
    border: 2px solid var(--background-7);
}

.button[disabled] path, .buttonDisabled path {
    fill: var(--text-secondary);
}

.buttonPrimary {
    composes: button;
    background-color: var(--background-accent-3);
    color: var(--text-accent);
    border: 2px solid var(--background-accent-2);
}

.buttonPrimary path {
    fill: var(--text-accent);
}

.buttonPopupFilled {
    composes: button;
    border-radius: 18px;
    color: var(--background-1);
    font-size: 16px;
    line-height: 19px;
    font-weight: 500;
    background-color: var(--text-accent);
    padding: 15px 70px;
}

.buttonPopupOutlined {
    composes: button;
    border-radius: 18px;
    color: var(--text-accent);
    font-size: 16px;
    line-height: 19px;
    font-weight: 500;
    padding: 15px 10px;
}

.rippleContainerPopupFilled, .rippleContainerPopupOutlined {
    border-radius: 18px;
}
