.container {
    display: grid;
    gap: 5px;
    grid-template-rows: auto 1fr;
    align-items: flex-start;
}

.title {
    white-space: pre;
}

.requiredMark {
    color: var(--dark-status-danger-d300);
}
