.app {
    display: grid;
    grid-template-columns: auto 1fr;
    height: 100vh;
    width: 100vw;
    overflow: hidden;
    background-color: var(--dark-secondary-fmain);
}

.app > *:nth-child(2) {
    min-width: 0;
    min-height: 0;
}

.notFound {
    display: flex;
    justify-content: center;
    align-items: center;
}

.notFoundImage {
    max-width: 600px;
}

.loadingContainer {
    display: grid;
    justify-content: center;
    align-content: center;
    justify-items: center;
    gap: 20px;
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: var(--background-1);
    text-align: center;
}

.construction {
    display: flex;
    flex-direction: column;
    gap: 25px;
    align-items: center;
    height: 100vh;
    justify-content: center;
}

.constructionAssistant {
    width: 200px;
}

.constructionText {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
}

.constructionTitle {
    font-family: "Roboto", sans-serif;
    color: var(--dark-white);
    font-weight: 400;
    font-size: 50px;
    line-height: 58px;
}

.constructionSubtitle {
    font-family: "Roboto", sans-serif;
    color: var(--dark-gray-p200);
    font-weight: 600;
    font-size: 20px;
    line-height: 23px;
}