.scrim {
    --modal-horizontal-padding: 16px;
    --modal-border-radius: 8px;

    position: absolute;
    display: grid;
    place-items: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--dark-gray-p500-50);
    z-index: 1000;
}

.modalCompact .scrim {
    --modal-horizontal-padding: 12px;
}

.modal {
    display: flex;
    flex-direction: column;
    background-color: var(--dark-gray-p500);
    border-radius: var(--modal-border-radius);
    border: 1px solid var(--dark-gray-p400);
    box-sizing: border-box;
}

/*region Header*/
.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: var(--modal-horizontal-padding);
    border-bottom: 1px solid var(--dark-gray-p400);
    flex-shrink: 0;
}

.headerClose {
    fill: var(--dark-white);
    cursor: pointer;
    padding: 27px 16px;
    flex-shrink: 0;
    border-top-right-radius: var(--modal-border-radius);
}

.modalCompact .headerClose {
    padding: 20px 16px;
}

.titles {
    display: flex;
    flex-direction: column;
}

.caption {
    display: -webkit-box;
    line-clamp: 2;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

/*endregion*/

/*region Content*/
.content {
    flex-grow: 1;
    padding: 16px var(--modal-horizontal-padding);
    overflow: auto;
}

.modalCompact .content {
    padding: 8px var(--modal-horizontal-padding);
}

/*endregion*/

/*region Footer Error*/
.error {
    padding: 5px var(--modal-horizontal-padding);
}

/*endregion*/


/*region Footer*/
.footer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-areas: "N P";
    align-items: center;
    padding: 16px var(--modal-horizontal-padding);
    gap: 10px;
    border-top: 1px solid var(--dark-gray-p400);
    flex-shrink: 0;
}

.modalCompact .footer {
    padding: 8px var(--modal-horizontal-padding);
}

.footerActions {
    display: flex;
    gap: 10px;
    align-items: center;
}

.footerNegativeActions {
    composes: footerActions;
    grid-area: N;
}

.footerPositiveActions {
    composes: footerActions;
    grid-area: P;
    justify-content: flex-end;
}

/*endregion*/
