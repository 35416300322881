.route {
    --route-padding: 30px;

    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
}

.header {
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-flow: column;
    align-items: center;
    gap: 20px;
    padding: 5px var(--route-padding) 5px var(--route-padding);
}

.content {
    flex: 1;
    overflow: auto;
    padding-top: 5px;
}

.headerContent {
    display: flex;
    gap: 20px;
    align-items: center;
    align-self: flex-end;
}

.title {
    flex: 1;
}

.contentWithNavigation {
    composes: content;
    padding-top: 15px;
}

.navigation {
    /*margin-top: 20px;*/
    padding: 0 var(--route-padding);
}

.errorContent {
    display: grid;
    justify-content: center;
    align-content: center;
    grid-template-columns: auto minmax(auto, 645px);
    gap: 35px;
    height: 100%;
}

.errorContentMessages {
    display: grid;
    gap: 30px;
    white-space: pre-line;
    overflow-y: auto;
}

.errorContentGlitch {
    overflow-wrap: anywhere;
    text-shadow: -3px -3px #DB404B, 7px -7px #2B63E0;
}
