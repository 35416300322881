/*region Popup*/
.popup {
    --popup-border-radius: 10px;
    --popup-background-color: var(--dark-secondary-p400);
    --suggestions-height: 250px;
    --suggestions-width: 250px;
    --corner-resizer-size: 10px;
    --resizer-size: 5px;

    display: flex;
    flex-direction: column;
    padding-bottom: var(--popup-border-radius);
    border-radius: var(--popup-border-radius);
    background-color: var(--popup-background-color);
    z-index: 999999;
    border: 1px solid var(--dark-gray-p400);
    position: relative;
    overflow: hidden;
}

.popupConnectedRight {
    border-radius: var(--popup-border-radius) 0 0 var(--popup-border-radius);
}

.popupConnectedLeft {
    border-radius: 0 var(--popup-border-radius) var(--popup-border-radius) 0;
}

/*endregion*/

/*region Resizer*/
.bottomResizer {
    position: absolute;
    bottom: 0;
    left: var(--corner-resizer-size);
    width: calc(100% - var(--corner-resizer-size) * 2);
    height: var(--resizer-size);
    cursor: n-resize;
}

.leftResizer {
    position: absolute;
    top: 0;
    left: 0;
    height: calc(100% - var(--corner-resizer-size));
    width: var(--resizer-size);
    cursor: w-resize;
}

.rightResizer {
    position: absolute;
    top: 0;
    right: 0;
    height: calc(100% - var(--corner-resizer-size));
    width: var(--resizer-size);
    cursor: w-resize;
}

.rightBottomResizer {
    position: absolute;
    top: calc(100% - var(--corner-resizer-size));
    right: 0;
    height: var(--corner-resizer-size);
    width: var(--corner-resizer-size);
    cursor: nw-resize;
}

.leftBottomResizer {
    position: absolute;
    top: calc(100% - var(--corner-resizer-size));
    left: 0;
    height: var(--corner-resizer-size);
    width: var(--corner-resizer-size);
    cursor: ne-resize;
}

/*endregion*/

/*region Search field*/
.searchWrapper {
    display: flex;
    gap: 5px;
    align-items: center;
    background-color: var(--dark-secondary-fmain);
    box-sizing: border-box;
    padding-right: 5px;
    padding-left: 10px;
}

.search {
    border: none;
    padding: 10px 5px 10px 0;
    color: var(--text-primary);
    background-color: transparent;
    font-size: 12px;
    line-height: 14px;
    flex: 1;
}

.search::placeholder {
    color: rgba(119, 123, 152, 1);
}

.search:focus {
    outline: none;
}

.searchClear {
    padding: 5px;
    cursor: pointer;
    border-radius: 50%;
}

.searchClear:hover {
    background-color: var(--background-12);
}

/*endregion*/

/*region Suggestions list*/
.suggestions {
    overflow: auto;
}

.suggestions::-webkit-scrollbar {
    background-color: transparent;
}

.suggestions::-webkit-scrollbar-thumb {
    border: 5px solid var(--popup-background-color);
}

.suggestionsPlaceholder {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 5px;
    height: 100%;
}

/*endregion*/

/*region Load error*/
.loadError {
    display: flex;
    flex-direction: column;
    justify-items: center;
    gap: 15px;
    padding: 20px 10px;
    height: var(--suggestions-height);
    overflow-y: auto;
}

.loadErrorMessage {
    text-align: center;
}

.retryBtn {
    overflow: initial;
}

/*endregion*/

/*region Suggestion*/
.suggestionBase {
    padding: 10px;
}

.suggestion {
    composes: suggestionBase;
    display: flex;
    gap: 8px;
    align-items: center;
}

.suggestionContent {
    display: flex;
    width: 100%;
    flex-direction: column;
}

.suggestionSkeleton {
    width: 100%;
}

.suggestionSelectable {
    display: grid;
    grid-template-columns: auto 1fr auto;
    gap: 8px;
    padding: 10px;
    align-items: center;
}

.suggestionInteractive {
    cursor: pointer;
    transition: 0.3s background-color;
}

.suggestionInteractive:hover {
    background-color: var(--background-4)
}

.suggestionTextContainer {
    display: flex;
    flex-direction: column;
}

.suggestionText {
    font-family: var(--font);
    color: var(--text-primary);
    font-size: 14px;
    line-height: initial;
    flex-grow: 1;
    word-break: break-word;
}

/*endregion*/

.loadingMore {
    display: grid;
    place-items: center;
}
