.container {
    position: relative;
    display: inline-block;
    overflow: hidden;
}

.containerButton {
    composes: container;
    overflow: initial;
}

.ripples {
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: none
}

.ripple {
    transform: scale(0);
    border-radius: 100%;
    position: absolute;
    background-color: var(--background-ripple);
    animation: ripple 500ms;
}

.rippleButton {
    composes: ripple;
}

@keyframes ripple {
    to {
        opacity: 0;
        transform: scale(2.5);
    }
}