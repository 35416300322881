.fieldsBadge {
    display: flex;
    gap: 8px;
    padding: 8px 12px;
    background-color: var(--dark-gray-p500);
    color: var(--dark-primary-main);
    border-start-start-radius: 15px;
    border-end-start-radius: 15px;
    align-items: center;
    cursor: pointer;
    border: 2px solid var(--dark-gray-p500);
}

.fieldsBadge:hover {
    border-color: var(--dark-focus);
}

.fieldsBadgeDropdown {
    fill: currentColor;
    stroke: currentColor;
    transition: transform .3s;
}

.fieldsBadgeDropdownOpened {
    composes: fieldsBadgeDropdown;
    transform: rotateZ(-180deg);
}

.fieldsBadgeTitle {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
