/*region Variants*/
.variantDefault {
    --head-background: var(--background-2);
    --head-shadow-color: #717688;
    --row-even-background: var(--dark-secondary-main);
    --row-hover-background: var(--background-12);
    --cell-font-size: 14px;
    --cell-line-height: 16px;
    --cell-text-color: var(--dark-white);
    --head-cell-text-color: var(--dark-gray-p200);
}

.variantEmbed {
    composes: variantDefault;
    --head-background: var(--dark-secondary-main);
    --row-even-background: var(--dark-secondary-fmain);
}

.variantModal {
    --head-background: var(--dark-gray-p500);
    --row-even-background: var(--dark-no-name-30);
    --row-hover-background: var(--background-12);
    --cell-font-size: 16px;
    --cell-line-height: 18px;
    --cell-text-color: var(--dark-white);
    --head-cell-text-color: var(--dark-gray-p100);
}

/*endregion*/

/*region Row*/
.highlight {
    transition: background-color .3s;
}

.table {
    position: relative;
}

.table > tr:nth-child(odd), .table > tbody > tr:nth-child(odd) {
    background-color: var(--row-even-background);
}

.highlight > tr:hover, .highlight > tbody > tr:hover {
    background-color: var(--row-hover-background);
}

.clickable {
    cursor: pointer;
}


/*endregion*/

/*region Cell*/
.cell {
    font-family: var(--font);
    font-size: var(--cell-font-size);
    line-height: var(--cell-line-height);
    color: var(--cell-text-color);
    text-align: left;
    vertical-align: middle;
}

.cellDefaultPadding {
    padding: 5px 5px;
}

/*endregion*/


/*region Header*/
.header {
    background-color: var(--head-background);
    /* position: sticky doesn't support borders */
    box-shadow: inset 0 -1px 0 var(--head-shadow-color);
}

.headerSticky {
    position: sticky;
    top: 0;
    z-index: 4;
}

.headerCell {
    composes: cell;
    padding: 10px 5px;
    color: var(--head-cell-text-color);
}

/*endregion*/